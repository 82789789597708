@font-face {
  font-family: "Anakotmai";
  src: url("../../public/static/fonts/Anakotmai-Light.woff2") format("woff2");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Anakotmai";
  src: url("../../public/static/fonts/Anakotmai-Medium.woff2") format("woff2");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Anakotmai";
  src: url("../../public/static/fonts/Anakotmai-Bold.woff2") format("woff2");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
